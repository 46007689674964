import React, { useLayoutEffect, useState, } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/svg/m-logo.svg'
import { BiLogIn } from 'react-icons/bi';
import { RiUploadCloud2Line } from 'react-icons/ri';
import { isUserLogin } from '../../middleware'
import { useSelector, useDispatch } from 'react-redux'
import { showPopUp } from '../../components/function';
import { Logout, UploadTransaction } from '../../customHook/HomeHook';
import { LoadingPage } from '../../components/LoadingPage'
import formatThousands  from 'format-thousands';
import { getAgentBalance } from '../../util/transaction-util';



const UploadRecieptPage = () => {
  const navigation = useNavigate()
  const user = useSelector((state) => state.agentQue.data)
  const [fileType, setFileType] = useState();
  const [file, setFile] = useState();
  const [isLoading, setisLoading] = useState(false)

  useLayoutEffect(() => {
    isUserLogin(navigation)
  }, [])

  const handleFile = (e) => {
    setFileType(e.target.files[0].type.split('/')[1]);
    setFile(e.target.files[0]);

    if (!fileType === 'png' || !fileType === 'jpeg' || !fileType === 'pdf' || !fileType === 'jpg') {
      showPopUp({
        type: "error",
        title: "Invalid file type",
        message: "jpeg', 'png', 'pdf', 'jpg supported"
      })
    }
  }
  const agent = useSelector((state) => state.agentData.data)
  const wallet = useSelector((state) => state.agentWallet.data)
  const agentBalance = getAgentBalance(wallet, agent?.country)
  if (isLoading)
    return < LoadingPage />
  return (
    <div className='container-main' >
      <div className="top-big-nav mobile-hide">
        <div className="header-container ">
          <div className="space-between">
            <div className="white-logo-cont" onClick={() => navigation('/home')}>
              <Logo className='logo-icon-white' />
              <p className="white-logo-text">
                afriex
              </p>
            </div>
            <button className="logout-div" onClick={() => Logout(navigation)}>
              <BiLogIn className='logout-icon' />
              <p className="log-out-txt">Logout</p>
            </button>
          </div>
          <p className="t-headr-name mt-2 mb-0-5">
            Hi, {agent?.firstName} {agent?.lastName}
          </p>
          <div className="hr-divider mb-0-7" />
          <div className="space-between align-center">
            <div>
              <p className="t-headr-number mb-0-3">
                {agentBalance.currency} {formatThousands(agentBalance.balance, {separator: ", "})}
              </p>
            </div>
            {/* <button onClick={()=> navigation('/withdraw')} className="withdraw-btn">
                      withdraw
                    </button> */}
          </div>
        </div>
      </div>

      <div className="card-container-white column-direction ">
        <h2 className='card-header-text mt-2 mb-2'>
          Upload Reciept
        </h2>
        <div className="home-card-container full-width-m justify-space-between-m  full-m-height">
          <div className="inner-div-content ">
            <RiUploadCloud2Line className='upload--icon mt-1' size={90} />
            <div className="desktop-hide mt-1">
            </div>
            <div className="align-center mb-2">
              <input className='file-input' type='file' onChange={handleFile} />
            </div>
            <div className="hr-dark full-width-m" />
          </div>
          <div className=" full-width-m">
            <div className="align-center mt-1 mb-3">
              <button className="blue-btn full-width-m" onClick={() => {
                if(fileType){
                  UploadTransaction({
                    user,
                    setisLoading,
                    navigation,
                    fileType,
                    file
                  })
                }else{
                  showPopUp({type:'error', title:'Transaction', message:'withdrawal request no longer available'});
                }
              }}>
                Upload image
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default UploadRecieptPage

