import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data:[],
   
  }

  export const agentTransaction = createSlice({
      name:'agentTransaction',
      initialState,

      reducers:{
          storeAgentTransaction: (state, action) => {
              const { payload }  = action
              state.data = payload;

          }
      }
  })

  export const  { storeAgentTransaction }  = agentTransaction.actions

  export default agentTransaction.reducer