import React, {useLayoutEffect, useState} from 'react'
import { ReactComponent as Logo} from '../../assets/svg/m-logo.svg'
import { BiLogIn } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom'
import { isUserLogin } from '../../middleware'
import { Logout, WithdrawalRequest, GetWallet, GetRate } from '../../customHook/HomeHook';
import { useSelector,  useDispatch } from 'react-redux'
import { LoadingPage } from '../../components/LoadingPage'
import { showPopUp  } from '../../components/function';
import { getAgentBalance } from '../../util/transaction-util';

 const  WithdrawPage = () => {
  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState();
  const [usdRate, setUsdtRate] = useState();
  const [usdt, setUsdt] = useState();
  const dispatch = useDispatch()
  const agent = useSelector((state) => state.agentData.data)
  const wallet = useSelector((state) => state.agentWallet.data)
  const agentBalance = getAgentBalance(wallet, agent?.country)
  const [isLoading, setisLoading] = useState(false)

  const navigation = useNavigate()

  useLayoutEffect(() => {
    isUserLogin(navigation)
    GetWallet({ dispatch })
    handleRateChange({})
   }, [])

   const getCountryRate =async(country, rate)=>{
      let countryRate
      console.log(countryRate)
    switch(country) {
      case 'ke':
        countryRate = rate.KES.rates.USD
        break;
      case 'ug':
        countryRate = rate.UGX.rates.USD
        break;
      case 'ng':
        countryRate = rate.NGN.rates.USD
        break;
      case 'gh':
        countryRate = rate.GHS.rates.USD
        break;
      default:
    }
    return countryRate
   }

   const handleRateChange =async(e) =>{
    // setAmount(e.target.value)
    let rate = await GetRate();
    let realRate = await getCountryRate(agent.country, rate)
    setUsdtRate(realRate)
    let total = amount * realRate
    setUsdt(total)
    
    console.log("rate",rate)
    // console.log("agent",agent)
    console.log("real rate",realRate)
    console.log("amount",amount)
   }

   const handleSubmit = async(e) => {
     e.preventDefault();
     if(!address || !amount) {
      showPopUp({type:'error', title:'fields cannot be empty'})
     }
  
     if((amount*usdRate) >= 10 ){
       WithdrawalRequest({setisLoading, navigation, amount, address})
     }else{
      showPopUp({type:'error', title:'amount must be grater than 10 USDT'})
     }
     
   }
  

  if (isLoading){
    return < LoadingPage />
  }


  return (
    <div className='container-main' >
        <div className="top-big-nav mobile-hide">
            <div className="header-container mobile-hide ">
                  <div className="space-between">
                  <div className="white-logo-cont">
                  <Logo className='logo-icon-white' onClick={() => navigation('/home')} />
                  <p className="white-logo-text">
                    afriex
                  </p>
                 </div>
                  <button className="logout-div" onClick={()=> Logout(navigation)}>
                      <BiLogIn className='logout-icon' />
                      <p className="log-out-txt">Logout</p>
                    </button>
                    
                  </div>
                  <p className="t-headr-name mb-0-5 mt-2">
                    Hi {agent?.firstName} {agent?.lastName}
                  </p>
                  <div className="hr-divider mb-0-7" />
                  <div className="space-between align-center">
                    <div>
                      <p className="t-headr-number mb-0-3">
                      {agentBalance.currency} {agentBalance.balance}
                    </p>
                    </div>
                    <button onClick={() => navigation('/home')} className="pri-blue">
                      Go back
                    </button>
                  </div>
            </div>
        </div> 
        <div className="card-container-white column-direction  m-white-border align-center justify-center">
                <div className="withdraw-inner">
                  <div className='align-center justify-center column-direction mt-3'>
                  <p className="withdraw-amount-text-i mb-1 mt-5">
                    Withdraw to a USDT address
                  </p>
                  <form  className="align-center justify-center column-direction mt-1">
                    <label htmlFor="">Valid USDT (ERC20) Address</label>
                    <input required className="MuiInputBase-input bg-white"  value={address} onInput={(e)=>setAddress(e.target.value)}  type="text" />
                    <label htmlFor="">Amount to withdraw rate:   (1{agentBalance.currency} = {usdRate ?? 0.00} USD)</label>
                    <input required className="MuiInputBase-input bg-white"  placeholder='0.00' type="number" value={amount} onInput={(e) => {setAmount(e.target.value); handleRateChange(e)}}  />
                    <h3 className="mb-3">{amount * usdRate ?? 0.00} USDT</h3>
                    <button className="blue-btn full-width-m mb-2" onClick={(e)=> handleSubmit(e)}>Withdraw</button>
                  </form>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default WithdrawPage
