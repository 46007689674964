import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import LoginPage from '../pages/auth/login'
import { HomePage } from '../pages/main/home';
import SectionPage from '../pages/main/section-page';
import SuccessPage  from '../pages/main/success';
import TransactionHistoryPage from '../pages/main/transaction-history';
import UploadRecieptPage from '../pages/main/upload-reciept';
import WithdrawPage from '../pages/main/withdraw'



const RootRoutes = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<LoginPage/>} />
            <Route path='/home' element={<HomePage />} />
            <Route path='/success' element={<SuccessPage />} />
            <Route path='/section' element={<SectionPage />} />
            <Route path='/upload-reciept' element={<UploadRecieptPage />} />
            <Route path='/transactions' element={<TransactionHistoryPage />} />
            <Route path='/withdraw' element={<WithdrawPage />} />
        </Routes>
    </BrowserRouter>
  )
}

export default RootRoutes