import React, { useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/svg/m-logo.svg'
import { ReactComponent as SuccessIcon } from '../../assets/svg/success-icon.svg'
import { isUserLogin } from '../../middleware';
import { useSelector, useDispatch } from 'react-redux';
import { DropTransaction, PaidTransaction } from '../../customHook/HomeHook';
import { LoadingPage } from '../../components/LoadingPage';
import Countdown from 'react-countdown';
import formatThousands  from 'format-thousands';
import { retrieveTransactionAmountInfo, retrievePaymentInfoForTransaction, getAgentBalance } from '../../util/transaction-util';
import { showPopUp  } from '../../components/function';


const SectionPage = () => {
  const navigation = useNavigate()
  const [isLoading, setisLoading] = useState(false)
  const dispatch = useDispatch()
  const [time, setTime] = useState();
  const user = useSelector((state) => state.agentQue.data)
  const agent = useSelector((state) => state.agentData.data)
  const wallet = useSelector((state) => state.agentWallet.data)
  const date = new Date(user?.transaction.defiBeganShowingAt);
  const transactionInfo = retrieveTransactionAmountInfo(user?.transaction, agent?.country)
  const paymentInfo = retrievePaymentInfoForTransaction(user?.transaction, agent?.country)
  const agentBalance = getAgentBalance(wallet, agent?.country)

  useLayoutEffect(() => {
    isUserLogin(navigation)
  }, [])
  if (isLoading)
    return < LoadingPage />

  return (
    <div className='container-main' >
      <div className="top-big-nav mobile-hide">
        <div className="header-container ">
          <div className="space-between">
            <div className="white-logo-cont" onClick={() => navigation('/home')}>
              <Logo className='logo-icon-white' />
              <p className="white-logo-text">
                afriex
              </p>
            </div>
            <div className="logo-icon"></div>
          </div>

          <p className="t-headr-name mt-2 mb-0-5">
            Hi, {agent?.firstName} {agent?.lastName}
          </p>
          <div className="hr-divider mb-0-5" />
          <div className="space-between align-center">
            <div>
              <p className="t-headr-number mb-0-5">
                {agentBalance.currency} {formatThousands(agentBalance.balance, {separator: ", "})}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="card-container-white column-direction  ">
        <h2 className='card-header-text mt-2 mb-2'>
          Payout Details
        </h2>
        <div className="home-card-container justify-space-between-m  full-m-height ">
          <div className="inner-div-content">

            <div className="space-between mt-1">
              <span className='r-turn-text1 grey-color pointer'>
                Amount:
              </span>
              <span className='r-turn-text1 pointer medium-bold'>
                {transactionInfo.currency} {formatThousands(transactionInfo.amount, {separator: ", "})}
              </span>
            </div>
            <div className="space-between">
              <span className='r-turn-text1 grey-color pointer'>
                Payment Method:
              </span>
              <span className='r-turn-text1 pointer medium-bold'>
                {paymentInfo.paymentMethod}
              </span>
            </div>
            <div className="space-between">
              <span className='r-turn-text1 grey-color pointer'>
              {paymentInfo.paymentMethod === 'Bank' ? 'Bank' : 'Provider'}
              </span>
              <span className='r-turn-text1 pointer medium-bold'>
                {paymentInfo.provider}
              </span>
            </div>
            <div className="space-between">
              <span className='r-turn-text1 grey-color pointer'>
                Account Name:
              </span>
              <span className='r-turn-text1 pointer medium-bold'>
                {paymentInfo.name}
              </span>
            </div>
            <div className="space-between">
              <span className='r-turn-text1 grey-color pointer'>
                Payout Number:
              </span>
              <span className='r-turn-text1 pointer medium-bold' onClick={() => {
                navigator.clipboard.writeText(paymentInfo.number);
                showPopUp({type:'success', title:'Account Number', message:'copied !!!'});
                }}>
                {paymentInfo.number}
              </span>
            </div>

            <div className="hr-dark full-width-m mt-1" />
          </div>
          <div className="inner-div-content">
            <div className="space-between mt-1 mb-3 ">
              <span className='r-turn-text1 pointer active-blue'
                onClick={() => {
                  DropTransaction({ user, setisLoading, navigation, dispatch })
                  localStorage.setItem('next', true)
                }}
              >
                Drop Transaction
              </span>
              <div>
                <button className="pri-blue" onClick={() => {
                  PaidTransaction({ user, dispatch, setisLoading, navigation })
                }}>
                  I have Paid
                </button>
                <div className="countdown">
                  <Countdown date={date.getTime() + 15 * 60 * 1000} />
                </div>
              </div>
            </div>
              <span className='r-turn-text2 alert-color '>
                Transactions will be returned back to the que if countdown elaspses
              </span>
          </div>
        </div>
      </div>
    </div>
  )
}


export default SectionPage

