export const showPopUp = (value) => {


  const { type, title, message } = value
  const newShow = () => {
    if (type === 'error') {
      document.getElementById('popUp-error').classList.add('show-popup')
      document.getElementById('popUp-success').classList.remove('show-popup')
      document.getElementById('popUp-title').innerHTML = title
      document.getElementById('popUp-message').innerHTML = message
    }
    if (type === 'success') {
      document.getElementById('popUp-error').classList.remove('show-popup')
      document.getElementById('popUp-success').classList.add('show-popup')
      document.getElementById('popUp-success-title').innerHTML = title
      document.getElementById('popUp-success-message').innerHTML = message
    }
  }
  setTimeout(() => {
    hidePopUp()
  }, 3000)
  newShow()
}
export const hidePopUp = (value) => {
  const newShow = () => {
    document.getElementById('popUp-error').classList.remove('show-popup')
    document.getElementById('popUp-success').classList.remove('show-popup')
  }
  newShow()
}
