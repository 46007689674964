import { PopUp } from './components/popUp';
import RootRoutes from './navigations/routes'


function App() {
  return (
   <>
   <PopUp />
   <RootRoutes />
   </>
  );
}

export default App;
