import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data:[],
    transaction:null,
  }

  export const agentQueSlice = createSlice({
      name:'agentQue',
      initialState,

      reducers:{
          storeAgentQue: (state, action) => {
              const { payload }  = action
              state.data = payload;

          },
          storeAgentQueTransaction: (state, action) => {
              const { payload }  = action
              state.data.transaction = payload;

          }
      }
  })

  export const  { storeAgentQue, storeAgentQueTransaction }  = agentQueSlice.actions

  export default agentQueSlice.reducer