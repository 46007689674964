import { BASE_URL, server } from '../util/axios-util'
import axios from 'axios'

const token = localStorage.getItem("token")

export default {
   auth:{
    login: data => server.post(`${BASE_URL}/authentication`, data)
  },
  main:{
    getAgentQue: () => server.get(`${BASE_URL}/agent-queue`),
    
    getTransaction: () => axios.get(`${BASE_URL}/agent-queue`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'afriex-client': 'admin'
      }
    }),

    claim: data => server.post(`${BASE_URL}/agent-queue`, data),

    drop: data => server.post(`${BASE_URL}/agent-queue`, data),

    skip: data => server.get(`${BASE_URL}/agent-queue?from${data}`),

    settle: data => server.post(`${BASE_URL}/agent-queue`, data)
    
  }
}


