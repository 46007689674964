import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/svg/m-logo.svg'
import { IoIosLogOut } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux'
import { BottomNav, } from '../../components/index'
import { LoadingPage } from '../../components/LoadingPage'
import { isUserLogin } from '../../middleware';
import { GetTransaction, SkipTransaction, ClaimTransaction, Logout, GetWallet } from '../../customHook/HomeHook';
import formatThousands  from 'format-thousands';
import { retrieveTransactionAmountInfo, getAgentBalance } from '../../util/transaction-util';

export const HomePage = () => {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const [isLoading, setisLoading] = useState(true)
  const user = useSelector((state) => state.agentQue.data)
  const agent = useSelector((state) => state.agentData.data)
  const wallet = useSelector((state) => state.agentWallet.data)
  const transactionInfo = user?.transaction ? retrieveTransactionAmountInfo(user.transaction, agent?.country) : null
  const agentBalance = getAgentBalance(wallet, agent?.country)
  const next = localStorage.getItem('next')
 

  useEffect(() => {
     isUserLogin(navigation)
     if(next){
      SkipTransaction({ user, dispatch, setisLoading, isSkip:false })
      localStorage.removeItem("next")
     }else{
       GetTransaction({ dispatch, setisLoading, navigation })
     }
     GetWallet({ dispatch })
  }, [])

  if (isLoading)
    return < LoadingPage />

  return (
    <div className='container-main' >
      <div className="top-big-nav ">
        <div className="mobile-card-amount desktop-hide ">
          <p className="text-moble-txt">Total Payouts</p>
          <p className="text-moble-txt2">{agentBalance.currency} {agentBalance.balance}</p>
        </div>
        <div className="header-container ">
          <div className="space-between mobile-hide">
            <div className="white-logo-cont" onClick={() => navigation('/home')} >
              <Logo className='logo-icon-white' />
              <p className="white-logo-text">
                afriex
              </p>
            </div>
            <button className="logout-div mb-2" onClick={() => Logout(navigation)}>
              <IoIosLogOut className='logout-icon' />
              <p className="log-out-txt">Logout</p>
            </button>
          </div>
          <p className="t-headr-name mb-0-5  ">
            Hi, {agent?.firstName} {agent?.lastName}
          </p>
          <div className="hr-divider mobile-hide mb-0-5" />
          <div className="space-between align-center mobile-hide">
            <div>
              <p className="t-headr-number mb-0-5">
                {agentBalance.currency} {formatThousands(agentBalance.balance, {separator: ", "})}
              </p>
            </div>
            <div>
              <button onClick={() => navigation('/transactions')} className="blue-btn">
                Transactions
              </button>
              <button onClick={() => navigation('/withdraw')} className="blue-btn">
                Withdraw
              </button>
            </div>
          </div>

        </div>
      </div>
      {user.transaction !== null &&
        (
          <div className="card-container-white column-direction ">
            <h2 className='card-header-text mt-2 mobile-hide'>
              Claimable Transaction
            </h2>
            <div className="home-card-container mt-4 ">
              <div className="inner-div-content mt-1">
                <div className="space-between mt-1 mb-1">
                  <span className='r-turn-text pointer'>
                    Amount
                  </span>
                  <span className='r-turn-text pointer medium-bold'>
                    {transactionInfo.currency} {transactionInfo.amount}
                  </span>
                </div>
              </div>
              <div className="hr-dark" />
              <div className="inner-div-content ">
                <div className="space-between mt-1 mb-1">
                  <span onClick={() => {
                    //  setisLoading(true)
                    SkipTransaction({ user, dispatch, setisLoading, isSkip:true })
                  }} className='r-turn-text pointer active-blue' >
                    Skip Transaction ({user?.skipInfo.maxNumberofSkips - user?.skipInfo.currentNumberOfSkips})
                  </span>
                  <button onClick={() => ClaimTransaction({ user, dispatch, setisLoading, navigation })} className="pri-blue outline-btn">
                    Claim Transaction
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      }

      {user.transaction === null && (
        <div className="card-container-white column-direction ">
          <h2 className='card-header-text mt-2 mobile-hide'>
            Claimable Transaction
          </h2>
          <div className="home-card-container mt-4 ">
            <div className="inner-div-content mt-1">
              <div className="justify-center mt-1 mb-1">
                <span className='r-turn-text pointer medium-bold'>
                  No Transactions at the moment
                </span>
              </div>
            </div>
            <div className="hr-dark" />
            <div className="inner-div-content ">
              <div className="justify-center mt-1 mb-1">
                <button onClick={() => { window.location.href = "/home" }} className="pri-blue outline-btn">
                  Refresh
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <BottomNav />
    </div>
  )
}