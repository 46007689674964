import React, { useLayoutEffect } from 'react'
import { ReactComponent as Logo } from '../../assets/svg/m-logo.svg'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as SuccessIcon } from '../../assets/svg/success-icon.svg'
import { BiLogIn } from 'react-icons/bi';
import { isUserLogin } from '../../middleware';
import { Logout } from '../../customHook/HomeHook';
import { useSelector } from 'react-redux'
import formatThousands  from 'format-thousands';
import { getAgentBalance } from '../../util/transaction-util';



const SuccessPage = () => {
  const navigation = useNavigate()
  const user = useSelector((state) => state.agentQue.data)
  const agent = useSelector((state) => state.agentData.data)
  const wallet = useSelector((state) => state.agentWallet.data)
  const agentBalance = getAgentBalance(wallet, agent?.country)
  useLayoutEffect(() => {
    isUserLogin(navigation)
  }, [])
  return (
    <div className='container-main' >
      <div className="top-big-nav mobile-hide">
        <div className="header-container ">
          <div className="space-between mb-2">
            <div className="white-logo-cont" onClick={() => navigation('/home')}>
              <Logo className='logo-icon-white' />
              <p className="white-logo-text">
                afriex
              </p>
            </div>
            <button className="logout-div" onClick={() => Logout(navigation)}>
              <BiLogIn className='logout-icon' />
              <p className="log-out-txt">Logout</p>
            </button>
          </div>
          <p className="t-headr-name mb-0-5">
            Hi {agent?.firstName} {agent?.lastName}
          </p>
          <div className="hr-divider mb-0-7" />
          <div className="space-between align-center">
            <div>
              <p className="t-headr-number mb-0-3">
                {agentBalance.currency} {formatThousands(agentBalance.balance, {separator: ", "})}
              </p>
            </div>
            <button className="withdraw-btn" onClick={() => navigation('/transactions')}>
              Transactions
            </button>
          </div>
        </div>
      </div>

      <div className="card-container-white  justify-space-between-m column-direction align-center full-m-height">
        <div className='full-width-m column-direction align-center'>
          <h2 className='success-t-text mt-2'>
            Transaction Successful
          </h2>
          <SuccessIcon className='suucess-icon mt-1 mb-1' />
          <p className="success-t-s column mb-1">
            Great work! You've succefully made  a payout
          </p>
          <div className="hr-dark mobile-hide mb-1" />
        </div>
        <div className="full-width-m column-direction align-center">
          <button className="blue-btn full-width-m" onClick={() => {
             localStorage.setItem('next', true)
             navigation("/home") 
             }}>
            Next Transaction
          </button>
          <span className='r-turn-text pointer' onClick={() => { navigation("/transactions") }}>
            View Transactions
          </span>
        </div>
      </div>
    </div>
  )
}

export default SuccessPage