import { ClipLoader, RotateLoader, BarLoader, HashLoader,  BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader } from "react-spinners";


export const LoadingPage = () => {
    return (
        <div className="loading-page">
            <RotateLoader color='#0063FE'   /> 
        </div>
    )
}


