import { notification } from '../components'
import apis from '../services/api'
import { showPopUp  } from '../components/function';
import {storeAgentData} from "../redux"



export const LoginHook = ({ setisLoading, model, navigation , dispatch}) => {
    setisLoading(true)
    apis.auth.login(model)
        .then((response) => {
            const { data } = response
            const { accessToken, user } = data

            localStorage.setItem('token', accessToken)
            
            if(user.role !== "agent"){
              setisLoading(false)
              return showPopUp({type:'error', title:'Login error', message:'only agents allowed'})
            }
          
            dispatch(storeAgentData(user))
            setisLoading(false)
            navigation("/home")
        })
        .catch((e) => {
            setisLoading(false)
            console.log(e)
            if (e.response.status === 401) {
                showPopUp({type:'error', title:'Login error', message:'invalid login'})
            }else if(e.response.status === 400){
                showPopUp({type:'error', title:'Login error', message:'agent country not supported'})
            }else{
                console.log(e);
                showPopUp({type:'error', title:'Server error', message:'oops something went wrong'})
            }           
        })

    return {

    }
}
