import { Input, Button, InputRightElement, Spinner, Box } from '@chakra-ui/react'
import React, { useState, useLayoutEffect, useEffect } from 'react'
import { BsEyeSlash, BsEye } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Loader, Margin } from '../../components';
import { ReactComponent as Logo } from '../../assets/svg/afriex-logo.svg'
import { LoginHook } from '../../customHook/LoginHook';
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useDispatch } from 'react-redux'


const LoginPage = () => {
  const navigation = useNavigate()
  const [show, setShow] = React.useState(false)
  const [isLoading, setisLoading] = useState(false)
  const handleClick = () => setShow(!show)
  const dispatch = useDispatch()

  useEffect(() => {
    const token = localStorage.getItem("token")
    if (token){
      return navigation('/home')
    }
  },[])
  const validate = Yup.object({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string()
      .min(4, 'Password must be at least 4 charaters')
      .required('Password is required'),
  })
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: validate,
    onSubmit: values => {
      const model = { ...values, "strategy": 'local' }
      LoginHook({ model, setisLoading, navigation, dispatch })
    },

  })

  return (
    <div className='container' >
      <Loader isLoading={isLoading} />
      <div className="auth-wrapper">
        <div className="card-element">
          <Logo className='logo-icon' />
          <form onSubmit={formik.handleSubmit} className="form-element">
            <p className="login-text-header mb-1">Login</p>
            <p className="login-text-title mb-2">Please enter your details and start transfering.</p>
            <p className="login-text-input">EMAIL ADDRESS</p>
            <div className="input-container ">
              <input
                type='text'
                name='email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className='MuiInputBase-input'
                placeholder='Email' />
              {/* <Input focusBorderColor='#00D192' type="text"  className="basic-input"/> */}
            </div>

            {formik.touched.email && formik.errors.email ? (
              <p className='error-text mb-1-5' >{formik.errors.email}</p>
            ) : <p className=' mb-1-5' ></p>}

            <p className="login-text-input">PASSWORD</p>

            <div className="input-container ">
              <input
                name='password'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                type={show ? 'text' : 'password'}
                placeholder="Password"
                className="MuiInputBase-input bg-white" />
              <span onClick={() => handleClick()}>
                {
                  show ?
                    <BsEye size={20} />
                    :
                    <BsEyeSlash size={20} />
                }
              </span>
            </div>
            {formik.touched.password && formik.errors.password ? (
              <p className='error-text mb-1-5' >{formik.errors.password}</p>
            ) : <p className=' mb-1-5' ></p>}
            {/* <div className="form-text-input "><span onClick={()=> navigation('/forget-password')} className='pointer f-password '>Forgot Password</span></div> */}
            <div >
              <Button
                colorScheme='#0063FE'
                width='100%'
                height='45px'
                loadingText='Submitting'
                bgColor='#0063FE'
                type='submit'
              >
                Sign in
              </Button>
            </div>
          </form>
        </div>

      </div>
    </div>
  )
}

export default LoginPage