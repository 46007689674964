
import axios from 'axios'
import { BASE_URL } from '../util/axios-util'
import { storeAgentQue, storeAgentWallet, storeAgentTransaction } from '../redux'
import { showPopUp  } from '../components/function';
import { array } from 'yup';

export const Logout = (navigation) => {
  localStorage.removeItem("token")
  navigation("/")
}


export const GetTransaction = async ({ dispatch, setisLoading, navigation }) => {
  await axios.get(`${BASE_URL}/agent-queue`, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    const { data } = response

    setisLoading(false)
    if (data.transacton !== null) {
      dispatch(storeAgentQue(data))
    }
    
    return

  })
    .catch((e) => {
      console.log(e)
      if (e.response.status === 401) {
        localStorage.removeItem("token")
        navigation("/")
        showPopUp({type:'error', title:'Session Expire', message:'please login again'});
       }
       if(e.response.status === 400){
          localStorage.removeItem("token")
          navigation("/")
          showPopUp({type:'error', title:'An Error Occur', message:e.response.data.message});
       }
    })


}

export const GetWallet = async ({ dispatch }) => {
  await axios.get(`${BASE_URL}/wallet-balance`, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    const { data } = response
    dispatch(storeAgentWallet(data))
    return 
  })
    .catch((e) => {
      
    })
}

export const GetRate = async ()  => {
  let rates
  await axios.get(`${BASE_URL}/rates`, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    const { data } = response
    rates = data
  }).catch((e) => {
      console.log(e)
    })
    return rates
}

export const GetMobileMoneyInfo = async () => {
  await axios.get(`${BASE_URL}/mobile-money-info`, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token")
    }
  }).then((response) => {
    const { data } = response
    console.log(data);
    return data
  })
    .catch((e) => {
     
    })
}

export const GetTransactionHistory = async ({ dispatch }) => {
  //return console.log('history')
  await axios.post(`${BASE_URL}/agent-queue`,
    {
      "action": "retrieveTransactions"
    }, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    const { data } = response
   
    dispatch(storeAgentTransaction(data))
  })
    .catch((e) => {

      if (e.response.status === 401) {
        showPopUp({type:'error', title:'Session Expire', message:'please login again'});

       }

    })
}


export const SkipTransaction = async ({ user, dispatch, setisLoading, isSkip}) => {
  setisLoading(true)
  let time = user?.transaction.createdAt
  let url=""
  if(isSkip === true){
    url = `${BASE_URL}/agent-queue?from=${time}&skip=true` 
  }else{
    url = `${BASE_URL}/agent-queue?from=${time}` 
  }
  await axios.get(url, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    const { data } = response
 
    if (typeof data !== "string") {
      dispatch(storeAgentQue(data))
    }
    setisLoading(false)
  })
    .catch((e) => {
   
      if (e.response.status === 401) {
        showPopUp({type:'error', title:'Session Expire', message:'please login again'});
      }
    })
}



export const ClaimTransaction = async ({ user, dispatch, setisLoading, navigation }) => {
  setisLoading(true)
  const id = user.transaction._id;
  await axios.post(`${BASE_URL}/agent-queue`,
    {
      "transactionId": id,
      "action": "claim"
    }, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    const { data } = response

  

    //  dispatch(storeAgentQue(data))

    setisLoading(false)
    navigation("/section")


  })
    .catch((e) => {
      setisLoading(false)
  
      if (e.response.status === 401) {
        showPopUp({type:'error', title:'Session Expire', message:'please login again'});
      }
      if (e.response.status === 404) {
        showPopUp({type:'error', title:'Transaction', message:'withdrawal request no longer available'});
      }
  
    })

}

export const DropTransaction = async ({ user, dispatch, setisLoading, navigation }) => {
  setisLoading(false)
  const id = user.transaction._id;
  await axios.post(`${BASE_URL}/agent-queue`,
    {
      "transactionId": id,
      "action": "drop"
    }, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    setisLoading(false)
    navigation("/home")
  })
    .catch((e) => {
      console.log(e.response)
      if (e.response.status === 401) {
        showPopUp({type:'error', title:'Session Expire', message:'please login again'});
       
      }
      if (e.response.status === 404) {
        showPopUp({type:'error', title:'Transaction', message:'withdrawal request no longer available'});
        navigation("/home")
      }
    })

}
export const WithdrawalRequest = async ({  setisLoading, navigation, amount, address}) => {
  setisLoading(true)
  console.log(amount)
  await axios.post(`${BASE_URL}/agent-queue`,
    {
      "amount": amount,
      "action": "withdraw",
      "address": address
    }, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    setisLoading(false)
    navigation("/withdraw")
    showPopUp({type:'success', title:'Withdrawal', message:'Withdrawal request was successful'});
  })
    .catch((e) => {
      setisLoading(false)
      console.log(e.response)
      if (e.response.status === 401) {
        showPopUp({type:'error', title:'Session Expire', message:'please login again'});
       
      }else{
        showPopUp({type:'error', title:'Withdrawal', message:'something went wrong'});
        navigation("/home")
      }
    })

}

export const PaidTransaction = async ({ user, dispatch, setisLoading, navigation }) => {
  setisLoading(false)
  const id = user.transaction._id;
  await axios.post(`${BASE_URL}/agent-queue`,
    {
      "transactionId": id,
      "action": "paid"
    }, {
    headers: {
      'Authorization': `Bearer `+ localStorage.getItem("token"),
      'afriex-client': 'admin'
    }
  }).then((response) => {
    setisLoading(false)
    navigation("/upload-reciept")
  })
    .catch((e) => {
      console.log(e.response)
      if (e.response.status === 401) {
        showPopUp({type:'error', title:'Session Expire', message:'please login again'});
      }
    })

}

export const UploadTransaction = async ({ user, fileType, file, setisLoading, navigation }) => {

  setisLoading(true)
  const id = user.transaction._id;

  const headers = {
    'Authorization': `Bearer `+ localStorage.getItem("token"),
    'afriex-client': 'admin'
  }
  // generate link
  await axios.post(`${BASE_URL}/agent-queue`,
    {
      "transactionId": id,
      "action": "generateUploadUrl",
      "fileType": fileType
    }, {
    headers: headers
  }).then(async (response) => {
    const { data } = response
    console.log(data?.url.split("?")[0])
   
    await fetch(data.url, { method: "PUT", body: file})
      .then((response) => {
        setisLoading(false)
       
        if (response.status === 200 || response.status === 201) {

          axios.post(`${BASE_URL}/agent-queue`,
            {
              "transactionId": id,
              "action": "settle",
              "transactionReceipt": data.url.split("?")[0]
            }, {
            headers: {
              'Authorization': `Bearer `+ localStorage.getItem("token"),
              'afriex-client': 'admin'
            }
          }).then((response) => {
            const { data } = response
            console.log(data, 'data')
            setisLoading(false)
            navigation("/success");
          })
            .catch((e) => {
              setisLoading(false)
              console.log(e.response)
            })
        } else {
          setisLoading(false)
        }
      })
      .catch((error) => {
        console.log("an error occur", error)
      })
  })
    .catch((e) => {
      console.log(e.response)
    })
}