import React,{useEffect} from 'react'
import { MdOutlineCancel } from 'react-icons/md';
import { hidePopUp, showPopUp } from './function'
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { MdCancel } from 'react-icons/md';



 
export const PopUp = ({children }) => {
  
      
     
  return (
    <>
    <div  id='popUp-success' className='side-popup-success align-center'>

        <div id='error-popup'  className='align-center' >
        <IoMdCheckmarkCircle size={30} className='popup-success-icon' />
        <div className="di">
        <p id='popUp-success-title'>Image uploaded succefully</p>
        <p id='popUp-success-message'>Image uploaded succefully</p>
        </div>
        </div>
      
      </div>


    <div  id='popUp-error' className='side-popup  align-center'>
        <MdCancel onClick={() => hidePopUp()} size={30} className='pointer popup-error-icon' />
        <div className="di">
        <p id='popUp-title'>Image uploaded succefully</p>
        <p id='popUp-message'>Image uploaded succefully</p>
        </div>
        
    </div>
    
    </>
    
  )
}
