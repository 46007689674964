import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data:[],
   
  }

  export const agentWalletSlice = createSlice({
      name:'agentWallet',
      initialState,

      reducers:{
          storeAgentWallet: (state, action) => {
              const { payload }  = action
              state.data = payload;
          }
      }
  })

  export const  { storeAgentWallet }  = agentWalletSlice.actions

  export default agentWalletSlice.reducer