export const supportedCountries = ['NG', 'KE', 'UG', 'GH']
export const supportedCurrencies = ['NGN', 'KES', 'UGX', 'GHS']
export const mobileMoneyCurrencies = ['KES', 'UGX', 'GHS']
export const currencyMap = {
  'NG': 'NGN',
  'KE': 'KES',
  'UG': 'UGX',
  'GH': 'GHS'
}

export const retrieveTransactionAmountInfo = (transaction, country) => {
  const {
    amount,
    currency,
    fulfillmentAsset,
    fulfillmentAssetValue
  } = transaction
  // check if currency is supported
  const baseCurrency = currencyMap[country?.toUpperCase()] || '';
  if (supportedCurrencies.includes(currency) && baseCurrency === currency) {
    return {
      amount: amount,
      currency
    }
  }
  if (supportedCurrencies.includes(fulfillmentAsset) && baseCurrency === fulfillmentAsset) {
    return {
      amount: fulfillmentAssetValue,
      currency: fulfillmentAsset
    }
  }
  throw new Error('Unsupported currency')
}

export const retrievePaymentInfoForTransaction = (transaction, country) => {
  const amountInfo = retrieveTransactionAmountInfo(transaction, country);
  const { currency } = amountInfo;
  if (mobileMoneyCurrencies.includes(currency)) {
    const mobileMoneyInfo = transaction.mobile_money_info;
    return {
      name: mobileMoneyInfo.name,
      provider: mobileMoneyInfo.provider,
      paymentMethod: 'Mobile Money',
      number: mobileMoneyInfo.number ? mobileMoneyInfo.number : mobileMoneyInfo.phone
    }
  }
  if (currency === 'NGN') {
    const bankInfo = transaction.bank_account_info;
    return {
      name: bankInfo.account_name,
      provider: bankInfo.bank_name,
      paymentMethod: 'Bank',
      number: bankInfo.account_number
    }
  }
  throw new Error('Unsupported currency for payment')
}

export const getAgentBalance = (wallet, country) => {
  const currency = currencyMap[country?.toUpperCase()] || '';

  if (!wallet || !wallet.fiat_balances) {
    return {
      balance: 0,
      currency
    }
  }

  // Get current balance from array
  const balance = wallet.fiat_balances.find(item => item.symbol === currency);

  if (!balance) {
    return {
      balance: 0,
      currency
    }
  }

  return {
    balance: balance.balance,
    currency
  }
}