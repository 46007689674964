import React, { useLayoutEffect, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as Logo } from '../../assets/svg/m-logo.svg'
import { ReactComponent as SuccessIcon } from '../../assets/svg/success-icon.svg'
import { useSelector, useDispatch } from 'react-redux'
import { Margin } from '../../components/index'
import { BiLogIn } from 'react-icons/bi';
import { isUserLogin } from '../../middleware';
import { Logout, GetTransactionHistory,GetWallet} from '../../customHook/HomeHook'
import dateFormat, { masks } from "dateformat";
import { storeAgentQueTransaction } from "../../redux"
import { BottomNav } from '../../components/index'
import formatThousands  from 'format-thousands';
import { retrieveTransactionAmountInfo, retrievePaymentInfoForTransaction, getAgentBalance } from '../../util/transaction-util';

const TransactionHistoryPage = () => {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    isUserLogin(navigation)
    GetWallet({ dispatch })
    GetTransactionHistory({ dispatch })
  }, [])
  useEffect(() => {
  }, [])
  const transactions = useSelector((state) => state.agentTransaction.data)
  console.log("transactions",transactions)
  const agent = useSelector((state) => state.agentData.data)
  const wallet = useSelector((state) => state.agentWallet.data)
  const agentBalance = getAgentBalance(wallet, agent?.country)
  return (
    <div className='container-main' >
      <div className="top-big-nav">
        <div className="header-container ">
          <div className="space-between">
            <div className="white-logo-cont" onClick={() => navigation('/home')}>
              <Logo className='logo-icon-white' />
              <p className="white-logo-text">
                afriex
              </p>
            </div>
            <button className="logout-div" onClick={() => Logout(navigation)}>
              <BiLogIn className='logout-icon' />
              <p className="log-out-txt">Logout</p>
            </button>
          </div>
          <p className="t-headr-name mb-2">
            Hi, {agent?.firstName} {agent?.lastName}
          </p>
          <div className="hr-divider mb-0-7" />
          <div className="space-between align-center">
            <div>
              <p className="t-headr-number mb-0-3">
                {agentBalance.currency} {formatThousands(agentBalance.balance, {separator: ", "})}
              </p>
            </div>
            <button onClick={() => navigation('/home')} className="pri-blue">
              Go back
            </button>
          </div>
        </div>
      </div>
      <div className="card-container-white column-direction ">
        <h2 className='card-header-text mt-2 mb-4'>
          Transaction History
        </h2>
        <div className="home-card-container">
          <div className="table-container">
            
            {transactions?.length === 0 ?
              <div className="card-container-white column-direction ">
                <div className="home-card-container mt-4 ">
                  <div className="inner-div-content mt-1">
                    <div className="space-between mt-4 mb-1">
                        No Transactions at the moment
                    </div>
                  </div>
                </div>
              </div>
              :
              <table className="mb-3">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="table-header">
                        <p>Date/Time</p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="table-header">
                        <p>Customer name</p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="table-header">
                        <p>Account Name</p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="table-header">
                        <p>Payment Method</p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="table-header">
                        <p>Amount</p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="table-header">
                        <p>Type</p>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="table-header">
                        <p>Status</p>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.data.map((transaction) => {
                    const transactionInfo = retrieveTransactionAmountInfo(transaction, agent?.country)
                    const paymentInfo = retrievePaymentInfoForTransaction(transaction, agent?.country)
                    return (
                      <tr>
                        <th  >
                          <div className="table-items">
                            <p>{dateFormat(transaction?.defiPaidAt, " d-m-yyyy, h:MM TT")}</p>
                            {transaction?.state === "Pending" && (
                              <button onClick={() => {
                                dispatch(storeAgentQueTransaction(transaction))
                                navigation('/upload-reciept')
                              }} className="withdraw-btn">
                                re-upload
                              </button>
                            )}
                          </div>
                        </th>
                        <td>
                          <div className="table-items">
                            <p>{transaction?.customer_details.name}</p>
                          </div>
                        </td>
                        <td>
                          <div className="table-items">
                            <p>{paymentInfo?.name || ''}</p>
                          </div>
                        </td>
                        <td>
                          <div className="table-items">
                            <p>{paymentInfo.provider || ''}</p>
                          </div>
                        </td>
                        <td>
                          <div className="table-items">
                            <p>{transactionInfo?.currency} {formatThousands(transactionInfo?.amount, {separator: ", "})}</p>
                          </div>
                        </td>
                        <td>
                          <div className="table-items">
                            <p>{transaction?.type_of_transaction}</p>
                          </div>
                        </td>
                        <td>
                          <div className="table-items">
                            <p>{transaction?.type_of_transaction === "agent-withdrawal" ? "Completed" : transaction?.state}</p>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            }
          </div>
        </div>
      </div>
      <BottomNav />
    </div>
  )
}


export default TransactionHistoryPage

