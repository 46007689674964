import agentQueSlice from "./main/storeUser"
import agentDataSlice from "./main/storeAgent"
import agentWalletSlice from "./main/storeAgentWallet"
import agentTransactionSlice from "./main/storeAgentTransaction"


const rootReducer = {
    agentQue : agentQueSlice,
    agentData : agentDataSlice,
    agentWallet : agentWalletSlice,
    agentTransaction : agentTransactionSlice
}


export default rootReducer