import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/main.scss'
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from '@chakra-ui/react'
import 'react-notifications/lib/notifications.css';
import './styles/responsive.scss'
import { store} from './redux/store'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ReactQueryDevtools } from 'react-query/devtools'



// import 'bootstrap/dist/css/bootstrap.min.css'; // Inside of your index.js
import swDev from "./swDev";

let persistor = persistStore(store);
const queryClient = new QueryClient()


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store} >
    <QueryClientProvider client={queryClient}>

    
        <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider>
          <App />
        </ChakraProvider>

        </PersistGate>
        <ReactQueryDevtools initialIsOpen={false} />


    </QueryClientProvider>

    
   
    
   

    </Provider>
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
swDev();