import { ClipLoader, BarLoader, HashLoader,  BeatLoader, BounceLoader, CircleLoader, ClimbingBoxLoader } from "react-spinners";


export const Loader = ({isLoading}) => {
    return (
        <>
            {
                isLoading && (
                    <div className="loader-container">
                        <div className="loader-card">
                            <HashLoader color='#0063FE'   /> 
                        </div>
                    </div>
                )
            }
        </>
    )
}