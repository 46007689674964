import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

const reducers = combineReducers(rootReducer)

const persistConfig = {
  key: 'root',
  storage,
};


const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,  // middleware: getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',

})

