

import { RiHomeFill } from 'react-icons/ri';
import { IoIosLogOut } from 'react-icons/io';
import { VscArrowSwap } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import { Logout} from '../customHook/HomeHook';




export const BottomNav = () => {
    const navigation = useNavigate()
    return (
        <div className="bottom-tab desktop-hide">
          <div className="b-t-nav-items" onClick={() => navigation('/home')}>
            <div className="icon-bottom-cont">
              <RiHomeFill className='b-nav-icon' size={30} />
            </div>
            <p className="b-nav-title">
              Home
            </p>
          </div>
          <div className="b-t-nav-items" onClick={() => navigation('/transactions')}>
            <div className="icon-bottom-cont">
              <VscArrowSwap className='b-nav-icon' size={30} />
            </div>
            <p className="b-nav-title">
              Transactions
            </p>
          </div>
          <div className="b-t-nav-items" onClick={() => navigation('/withdraw')}>
            <div className="icon-bottom-cont">
              <IoIosLogOut className='b-nav-icon' size={30} />
            </div>
            <p className="b-nav-title">
             Withdraw
            </p>
          </div>
          
        </div>
    )
}