import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    data:[],
   
  }

  export const agentDataSlice = createSlice({
      name:'agentData',
      initialState,

      reducers:{
          storeAgentData: (state, action) => {
              const { payload }  = action
              state.data = payload;
          }
      }
  })

  export const  { storeAgentData }  = agentDataSlice.actions

  export default agentDataSlice.reducer